import { CreateControllerFn } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({flowAPI}) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  const isMobile =flowAPI.environment.isMobile;
  const { setProps } = flowAPI.controllerConfig;

  return {
    async pageReady() {
      setProps({isMobile, modalId: `${Math.random()}`})
    },
  };
};

export default createController;
